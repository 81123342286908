<template>
  <div class="layout-header-section d-lg-flex d-block justify-content-between">
    <div class="header-label col-lg-6 col-12">Guests Report</div>
    <div
      class="col-lg-6 col-12 text-end d-flex justify-content-end custom-flex-cloumn-mob"
    ></div>
  </div>
  <div class="company-section-outer py-3" style="height: calc(100vh - 141px)">
    <div class="row g-3">
      <div class="col-lg-4 col-md-4 col-12">
        <div class="feedback-chart-outer pb-3">
          <div class="chart-label text-center">Gender Wise Report</div>
          <div style="position: relative; min-height: 350px">
            <div v-if="!showloadergenderreport">
              <Chart
                type="doughnut"
                :data="chartDataGender"
                :options="lightOptions"
                responsive="true"
              />
            </div>
            <div v-if="showloadergenderreport">
              <div class="custom-modal-spinner-loader">
                <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-12">
        <div class="feedback-chart-outer pb-3">
          <div class="chart-label text-center">Area Wise Report</div>
          <div style="position: relative; min-height: 350px">
            <div v-if="!showloaderproreport">
              <Chart
                type="doughnut"
                :data="chartDataProfession"
                :options="lightOptions"
                responsive="true"
              />
            </div>
            <div v-if="showloaderproreport">
              <div class="custom-modal-spinner-loader">
                <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-12">
        <div class="feedback-chart-outer pb-3">
          <div class="chart-label text-center">Country Wise Report</div>
          <div style="position: relative; min-height: 350px">
            <div v-if="!showloaderblood">
              <Chart
                type="doughnut"
                :data="chartDataBloodGroup"
                :options="lightOptions"
                responsive="true"
              />
            </div>
            <div v-if="showloaderblood">
              <div class="custom-modal-spinner-loader">
                <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ApiService from "../../../service/ApiService";

export default {
  data() {
    return {
      showloadergenderreport: false,
      showloaderproreport: false,
      showloaderblood: false,
      ReportChartDetails: "",
      chartDataGender: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
            hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
          },
        ],
      },
      chartDataProfession: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
            hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
          },
        ],
      },
      chartDataBloodGroup: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
            hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
          },
        ],
      },
      lightOptions: {
        plugins: {
          legend: {
            labels: {
              color: "#495057",
            },
          },
        },
      },
    };
  },
  ApiService: null,
  created() {
    this.ApiService = new ApiService();
  },
  mounted() {
    this.showloadergenderreport = true;
    this.showloaderproreport = true;
    this.showloaderblood = true;
    // this.getvoterreportschart();
  },
  methods: {
    getvoterreportschart() {
      this.ApiService.getmeranetagenderdetails().then((data) => {
        if (data.success == true) {
          this.chartDataGender = {
            labels: data.labels,
            datasets: [
              {
                data: data.data,
                backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
                hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
              },
            ],
          };
          this.showloadergenderreport = false;
        } else {
          this.showloadergenderreport = false;
        }
      });
      this.ApiService.getmeranetaprofessiondetails().then((data) => {
        if (data.success == true) {
          this.chartDataProfession = {
            labels: data.labels,
            datasets: [
              {
                data: data.data,
                backgroundColor: [
                  "#FF6384",
                  "#36A2EB",
                  "#FFCE56",
                  "#808000",
                  "#008000",
                  "#008080",
                  "#000080",
                  "#808080",
                  "#F08080",
                  "#FF8C00",
                  "#556B2F",
                ],
                hoverBackgroundColor: [
                  "#FF6384",
                  "#36A2EB",
                  "#FFCE56",
                  "#808000",
                  "#008000",
                  "#008080",
                  "#000080",
                  "#808080",
                  "#F08080",
                  "#FF8C00",
                  "#556B2F",
                ],
              },
            ],
          };
          this.showloaderproreport = false;
        } else {
          this.showloaderproreport = false;
        }
      });
      this.ApiService.getmeranetabloodgroupdetails().then((data) => {
        if (data.success == true) {
          this.chartDataBloodGroup = {
            labels: data.labels,
            datasets: [
              {
                data: data.data,
                backgroundColor: [
                  "#FF6384",
                  "#36A2EB",
                  "#FFCE56",
                  "#808000",
                  "#008000",
                  "#008080",
                  "#000080",
                  "#808080",
                  "#F08080",
                  "#FF8C00",
                  "#556B2F",
                ],
                hoverBackgroundColor: [
                  "#FF6384",
                  "#36A2EB",
                  "#FFCE56",
                  "#808000",
                  "#008000",
                  "#008080",
                  "#000080",
                  "#808080",
                  "#F08080",
                  "#FF8C00",
                  "#556B2F",
                ],
              },
            ],
          };
          this.showloaderblood = false;
        } else {
          this.showloaderblood = false;
        }
      });
    },
  },
};
</script>
<style scoped>
.company-section-outer {
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
  /* height: 520px; */
}

.company-section-outer::-webkit-scrollbar {
  display: block;
}

.company-section-outer::-webkit-scrollbar {
  width: 0.3em;
  height: 0.3em;
}

.company-section-outer::-webkit-scrollbar-thumb {
  background-color: rgb(113 115 119 / 49%);
  border-radius: 3px;
}

.feedback-chart-outer {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e0e5ed;
  border-radius: 4px;
}

.feedback-chart-outer .chart-label {
  font-family: "AcuminPro-SemiBold";
  font-size: 16px;
  letter-spacing: 0.32px;
  color: #4a5463;
  text-align: left;
  line-height: 16px;
  padding: 16px;
}
</style>